
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute } from "vue-router";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";
import PPaginator from "primevue/paginator";
import CDropdown, { DropdownItem } from "@/components/Dropdown.vue";
import PBreadcrumb from "primevue/breadcrumb";

import { usePagination, formatCNPJ } from "@/libs/utils";
import { Establishment } from "@/store/establishment/types";

const VEstablishmentList = defineComponent({
  name: "VEstablishmentList",
  components: { PDataTable, PColumn, PProgressBar, PPaginator, CDropdown, PBreadcrumb },
  setup() {
    useHead({ title: "Lista de clínicas | obmed" });

    const store = useStore();
    const route = useRoute();

    const establishments = computed(() => store.state.establishment.list);
    const loading = reactive({ list: false });

    function getDropdownOptions(establishment: Establishment) {
      const items: DropdownItem[] = [
        {
          label: "Visualizar",
          icon: "fas fa-info-circle",
          to: { name: "establishment-edit", params: { _id: String(establishment.id) } },
        },
        {
          label: "Procedimentos",
          icon: "fas fa-briefcase-medical",
          to: {
            name: "establishment-procedure-list",
            query: { cd_estabelecimento: String(establishment.id), close: true },
          },
        },
        {
          label: "Editar",
          icon: "fas fa-edit",
          to: { name: "establishment-edit", params: { _id: String(establishment.id) } },
        },
        {
          label: "Remover",
          icon: "fas fa-trash-alt",
          to: { name: "establishment-delete", params: { _id: String(establishment.id) } },
        },
      ];

      return items;
    }
    const breadcrumb = [
      {
        label: "Clínicas",
        icon: "fas fa-hospital-alt mr-1",
        to: { name: "establishment-list", query: route.query },
      },
    ];

    const { pagination, handleUpdateCurrentPage } = usePagination({
      module: "establishment",
      setPage: "setEstablishmentPaginationPage",
      updateList: getEstablishments,
    });

    async function getEstablishments() {
      loading.list = true;
      await store.dispatch("getEstablishments");
      loading.list = false;
    }

    getEstablishments();

    return {
      ...{ establishments, pagination, loading, breadcrumb },
      ...{ getDropdownOptions, handleUpdateCurrentPage, getEstablishments, formatCNPJ },
    };
  },
});

export default VEstablishmentList;
